.selectedImage {
    display: flex;
    justify-content: center;
    position: relative;
    height: 512px;

    .previousNextImage {
        height: 100%;
        position: absolute;
        display: flex;
        padding: 32px;
        align-items: center;
        cursor: pointer;
        opacity: 0;
        transition: 0.3s;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 4;

        &:hover {
            opacity: 1;
        }

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }

    .mainImage {
        overflow: hidden;
        object-fit: contain;
        object-position: center;

        &.clickable {
            cursor: pointer;
        }
    }
}