.grid {
    display: flex;
    flex-wrap: wrap;
    min-width: 384px;
    width: 90%;
    justify-content: center;
    margin: 0 auto;
    transition: 0.2s;

    & > * {
        margin: 16px;
    }
}