@import "../../../utils/style/colors.scss";

.centeringContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .mainCard {
        width: 384px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 16px 32px 16px;

        .textSiteName {
            font-weight: lighter;
            color: $main-theme-yellow;
        }

        .textDetails {
            margin: 0 16px 32px 16px;
            text-align: center;
        }

        .emailField {
            margin: 32px 0;
        }
    }
}