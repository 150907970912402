@import "../../../utils/style/colors.scss";

.hero {
    width: 100vw !important;
    height: 96vh;
    justify-content: left;
    position: relative;
    padding: 0 !important;

    .heroOverlay {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .85);
        position: absolute;
        clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%, 0 0);
        top: 0;
        z-index: 1;
    }

    .heading {
        opacity: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 48px;
        width: 50%;
        padding-left: 24px;
        z-index: 2;

        h1 {
            font-size: 64px;
            color: $main-theme-dark-pink;
            margin-bottom: 0;
        }

        h3 {
            color: #555;
            font-weight: lighter;
            margin-bottom: 0;
        }

        .actions {
            display: flex;
            flex-wrap: wrap;
            margin: 16px -0.4rem;

            .actionButton {
                background: #eaa524aa;
                color: white;
                padding: 12px 28px;
                margin: .4rem;
                min-width: 100px;

                &:hover {
                    background: $main-theme-yellow;
                }
            }
        }

        .partners {
            padding-bottom: 200px;
        }
    }

    .imageWrapper {
        position: absolute;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;

        & > img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

@media (max-width: 1366px) {
    .hero .heading {
        margin-left: 15%;
    }
}

@media (max-width: 1200px) {
    .hero {
        .heroOverlay {
            clip-path: none;
        }

        .heading {
            width: 70%;
            padding-left: 0;
        }
    }
}

@media (max-width: 650px) {
    .hero .heading .actions .actionButton {
        padding: .5rem;
        font-size: .8rem;
    }
}

@media (max-width: 550px) {
    .hero .heading h1 {
        font-size: 48px;
    }
}

@media (max-width: 400px) {
    .hero .heading h1 {
        font-size: 36px;
    }
}

@media (max-height: 650px) {
    .hero .heading h3 {
        margin-bottom: 24px;
    }
}