div.container {
    padding: 32px 0;

    & > h5 {
        margin-bottom: 16px;
    }

    div.panoramaWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        & > iframe {
            overflow: hidden;
        }
    }
}