@import "../../utils/style/colors.scss";

.adminWrapper {
    display: flex;
}

.header {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1000;

    .miraCard {
        height: 50px;
        padding: 12px;
        box-shadow: 3px 3px 20px rgba(0, 0, 0, .16);
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .downArrow {
            color: $main-theme-yellow;
            font-size: 32px;
            margin-left: 16px;
        }
    }

    .searchBar {
        flex-grow: 4;
        display: flex;
        margin: 0 16px;

        input {
            width: 100%;
            padding: 5px 16px;
            border: 1px solid #eee;
            outline: none;
            font-size: 20px;
            color: $main-theme-dark-pink;
            border-radius: 4px;
        }

        ::placeholder {
            color: #ccc;
            font-size: 16px;
        }
    }
}

@media (max-width: 1100px) {
    .header {
        .searchBar {
            width: 100%;
            margin-bottom: 16px;
        }
    }
}

.panels {
    width: 80vw;
    height: 100vh;
    padding: 32px;
    flex-grow: 999;
    overflow: auto;
}

@media (max-width: 720px) {
    .panels {
        margin-top: 50px;
    }
}