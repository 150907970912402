.notFoundWrapper {
    width: calc(100% - 32px);
    margin: 32px 16px 16px;
    padding: 12px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, .16);
    background: white;
    z-index: 0;

    .notFound {
        text-align: center;
    }
}