@import "../../utils/style/colors.scss";

.bidInputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .selector {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $main-theme-yellow;
        border-radius: 4px;
    
        .button {
            min-width: 24px;
            font-weight: bolder;
            font-size: 16px;
            color: white;
            background: $main-theme-yellow;
            border: none;
            border-radius: 0px;
        }

        .price {
            font-weight: bolder;
            font-size: 22px;
            color: white;
            color: $main-theme-yellow;
            padding: 0 24px;
            border-radius: 4px;
        }
    }

    .submit {
        width: 100%;
        padding: 3px 0;
        border-radius: 4px;
        background: $main-theme-yellow;
        color: white;
        border: 2px solid $main-theme-yellow;
        margin-top: 4px;
        font-size: 12px;

        &:hover {
            color: $main-theme-yellow;
        }
    }
}