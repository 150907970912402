@import "../../utils/style/colors.scss";

.wrapperContainer {
    font-family: 'Roboto', 'sans-serif';
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    background: white;
    overflow-x: hidden;
    color: $main-theme-dark-pink;

    .header {
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 64px;
        width: 100%;
        display: block;
        text-align: center;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 72px;
            height: 3px;
            background: #2c222c99;
            left: calc(50% - 36px);
            bottom: -24px;
        }
    }

    .text {
        margin-top: 0;
        font-size: 19px;
        letter-spacing: 2px;
        line-height: 32px;
        opacity: 0;
    }

    .homeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 16px;
        width: 100%;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .section {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 48px 48px 72px;

        .hoverableCard {
            border-radius: 8px;
            padding: 16px;
            transition: all .2s ease-in-out;
            opacity: 0;
        }

        .hoverableCard:hover {
            transform: scale(1.05);
            box-shadow: 2px 2px 10px #ddd;
        }
    }
}

@media (max-width: 1280px) {
    .wrapperContainer .section {
        width: 90%;
        padding: 0 24px 24px;
    }
}

@media (max-width: 800px) {
    .wrapperContainer .section {
        width: 95%;
        padding: 0 12px 12px;
    }
}