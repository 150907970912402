.navigationBar {
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    
    .buttons {
        display: flex;
        align-items: center;

        .addButton {
            transition: 0.5s;
            opacity: 0;
            cursor: default;
        }

        .spinner {
            height: 24px !important;
            width: 24px !important;
        }
    }
}

.dummyToolbar {
    min-height: 48px !important;
}