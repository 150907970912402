@import "utils/style/colors.scss";

button.button {
    position: relative;
    border-radius: .7rem;
    padding: 8px;
    margin: 4px;

    &.selected {
        background-color: white;
    
        &:hover {
            background-color: white;
        }

        .unseenDot.visible {
            background-color: $main-theme-yellow;
            color: white;
        }
    }

    .unseenDot {
        display: none;
        position: absolute;
        background-color: white;
        font-size: .7rem;
        border-radius: 1rem;
        padding: .2em;
        top: .3rem;
        right: .3rem;
        color: $main-theme-yellow;
        line-height: normal;
        height: 1.5em;
        width: 1.5em;

        &.visible {
            display: initial;
        }
    }
}
