@import "../../utils/style/colors.scss";

.imageItemWrapper {
    height: 256px;
    width: 256px;
    background: $main-theme-yellow;
    border-radius: 16px;
    margin: 8px;

    & > input {
        display: none;
    }

    .addWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        cursor: pointer;
        user-select: none;

        .addOverlay {
            opacity: 0;
            position: absolute;
            left: 4px;
            top: 4px;
            right: 4px;
            bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            transition: all 0.5s;
            border-radius: 12px;

            &:hover {
                opacity: 1;
            }
        }
    }

    .imageWrapper {
        height: 100%;
        padding: 4px;
        position: relative;

        & > img {
            height: 100%;
            width: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
            border-radius: 12px;
        }

        .remove {
            position: absolute;
            height: 40px;
            width: 40px;
            top: -12px;
            right: -12px;
            transition: all 0.5s;
            background-color: red;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            cursor: pointer;
        }

        &:hover .remove {
            opacity: 1;
        }
    }

    .spinnerWrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner {
            height: 96px !important;
            width: 96px !important;
        }
    }
}