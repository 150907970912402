.characteristicItemWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 4px;
    transition: 0.3s;
    width: 128px;
    height: 128px;
    padding: 4px;

    &:hover {
        box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),
            0px 3px 4px 0px rgba(0,0,0,0.14),
            0px 1px 8px 0px rgba(0,0,0,0.12);
    }

    p {
        font-weight: 600;
    }

    .details {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: white;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4px;
        opacity: 0;

        &:hover {
            opacity: 0.95;
        }
    }
}