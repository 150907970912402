@import "../../../utils/style/colors.scss";

.contactHeader {
    margin-bottom: 96px;
}

.contactItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    width: 300px;
    height: 320px;
    background: white;
    box-shadow: 2px 2px 15px #ddd;
    border-radius: 8px;
    margin: 75px 12px 48px;
    transition: all .2s ease-in-out;
    opacity: 0;

    .contactIconWrapper {
        width: 75px;
        height: 75px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $main-theme-yellow;
        border-radius: 50%;
    }

    .contactIcon {
        color: white;
    }

    .contactType {
        margin: 48px 0;
        color: #333;
    }

    .assistant {
        color: #777;
        font-weight: 500;
        margin: 8px;
        text-align: center;
        width: 90%;
    }

    .contact {
        font-weight: 400;
        color: #999;
        margin-top: 0;
        margin-bottom: 8px;
        text-decoration: none;
    }

    .contactEmail {
        color: $main-theme-yellow;
        letter-spacing: 1px;
        font-weight: 500;
        text-decoration: none;
        margin-top: 16px;
    }

    &:hover {
        box-shadow: 2px 2px 15px #ddd;
        transform: scale(.99);
    }
}