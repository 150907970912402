.imageGalleryWrapper {
    width: 100%;
    user-select: none;
}

.fullscreenGallery {
    height: 100vh;
    width: 100vw;
    user-select: none;

    .fullscreenWrapper {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);

        .selectedImage {
            width: 100%;
            padding-top: 16px;
            height: calc(100% - 112px);

            .counter {
                font-weight: bold;
                color: white;
                padding: 8px;
                background-color: rgba(0, 0, 0, 0.7);
                bottom: 8px;
                margin: 0 auto;
                position: absolute;
            }

            .closeFullscreen {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 64px;
                padding: 8px;
                height: 52px;
                width: 52px;
                background-color: rgba(0, 0, 0, 0.6);
                top: 16px;
                right: 16px;
                position: absolute;
                z-index: 8;
            }
        }
    }
}