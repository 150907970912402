div.forRentAddCharacteristicsWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .characteristicWrapper {
        height: 64px;
        min-width: 256px;
        max-width: 720px;
        width: 100%;
        padding: 16px 32px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid rgb(230, 230, 230);

        div.floorPicker {
            display: flex;
            margin-top: -16px;
            margin-bottom: -16px;

            div.levelsInput {
                display: flex;
                align-items: baseline;
                font-weight: bold;
                margin-bottom: 16px;

                & > label {
                    margin-left: 2px;

                    & > div > div {
                        min-width: 96px;
                    }
                }
            }

            & > label {
                margin-right: 2px;
            }
        }
    }
}