@import "../../../utils/style/colors.scss";

.centeringContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .mainCard {
        display: flex;
        max-height: 80vh;
        flex-direction: column;
        align-items: center;
        padding-top: 32px;
        padding-right: 0;
        padding-left: 0;

        .textSiteName {
            font-weight: lighter;
            color: $main-theme-yellow;
        }

        .dropdownAccountType {
            margin-top: 32px;
        }

        .fields {
            overflow-y: auto;
            padding: 0 16px 16px 32px;
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;

            .inputField {
                width: 320px;
                margin-top: 16px;
            }
        }

        .checkAgreement {
            margin: 0 32px 16px;
        }
    }
}