.postDetailsPageContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .content {
        margin: 32px 0;
        width: calc(100% - 64px);
        max-width: 1024px;
        position: relative;

        .idDisplay {
            font-weight: 550;
        }

        .verifiedBadge {
            position: absolute;
            top: 16px;
            right: 16px;
            color: #5FD79C;
            width: 32px;
            height: 32px;
        }

        .location {
            margin: 8px 0;
            display: flex;
            align-items: center;

            & > p {
                margin-left: 4px;
                font-weight: 500;
            }
        }

        .mainInformation {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .panelWrapper {
                width: 50%;
                min-width: 256px;
                margin-top: 32px;

                &.fullWidth {
                    width: 100%;
                }

                .descriptionHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                }

                pre {
                    white-space: pre-wrap;
                    overflow-wrap: break-word;
                }
            }
        }
    }

    .noPostFound {
        margin: auto;
    }
}