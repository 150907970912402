@import "utils/style/colors";

.container {
    position: relative;
    padding: 1rem;

    &.compact {
        padding-bottom: 0;
    }

    &:hover {
        background-color: #faa20015;
    }

    .timestamp {
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
        text-transform: capitalize;
    }

    .unreadDot {
        position: absolute;
        top: .5rem;
        right: .5rem;
        background-color: $main-theme-yellow;
        height: .7rem;
        width: .7rem;
        border-radius: .5rem;
    }
}