@import "../../../../../../../../utils/style/colors.scss";

.actionsWrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 20px rgba(161, 161, 161, .36);
    width: calc(100% - 32px);
    border-radius: 4px;
    margin: 0 16px 16px;
    padding: 24px 50px;

    .actions {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .actionButton {
            margin: 0 16px 16px 0;
            padding: 4px 16px;
        }

        .verifyUser {
            background: $color-success;
            color: white;
        }

        .banUser {
            background: $color-info;
            color: white;

            &:hover {
                background: rgba(255, 136, 0, .8);
            }
        }

        .makeUserAdmin {
            background: $main-theme-yellow;
            color: white;

            &:hover {
                background: #eaa524dd;
            }
        }

        .viewUserPosts {
            background: $color-primary;
            color: white;
        }
    }
}