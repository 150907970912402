.userBiddings {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    padding: 0 50px 50px;
    margin: 0 16px 16px;
    border-radius: 4px;
    box-shadow: 2px 2px 20px rgba(161, 161, 161, .36);
    max-height: 626px;
    overflow: auto;

    h3 {
        margin-top: 41px;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        thead>tr {
            text-align: center;

            th {
                text-align: center;
                display: table-cell;
                position: sticky;
                top: 0;
                background: white;
                padding: 16px 0;
            }
        }
    }

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #efefef;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #eaa52481;
    }
}

.noBidsWarning {
    text-align: center;
}

@media (max-width: 600px) {
    .userBiddings table {
        width: 800px !important;
    }
}