@import "../../../../utils/style/colors.scss";

.filterBar {
    z-index: 1024 !important;
    position: sticky !important;

    & > div {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        div.searchField {
            min-width: 192px;
            width: 25%;

            & > div > input {
                color: white;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }
            } 
        }

        .buttonFilters {
            margin-left: 32px;
        }

        .myPostsButton {
            margin-left: 16px;

            &.activeButton {
                &:active {
                    background-color: #d5d5d5;
                }

                &:hover {
                    background-color: #d5d5d5;
                }

                & > span {
                   color: $main-theme-yellow;
                }
            }
        }

        .whiteText {
            color: white;
        }

        & > label > div > svg:last-child {
            color: white;
        }
    }
}

.dialogFiltersContainer {
    min-width: 256px;
    max-width: 1024px;
}