@import "utils/style/colors";

h6.small {
    font-size: 1rem;
}

.postDetails {
    border: 0.1rem solid rgb(224, 224, 224);
    border-radius: .7rem;
    display: flex;

    img {
        width: 12rem;
        margin-right: 1rem;
        border-radius: .7rem 0 0 .7rem;

        &.compact {
            width: 8rem;
        }
    }

    .postTitleAndBid {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: calc(100% - 13rem);

        .ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h5 {
            color: $main-theme-yellow;
            text-align: end;
            margin-right: 1em;
        }
    }
}

.auctionActions {
    display: flex;
    justify-content: flex-end;

    a {
        text-decoration: none;

        button {
            margin: .5rem;
        }
    }
}