@import "../../../../../utils/style/colors.scss";

.actionsWrapper {
    float: right;

    .actionsToggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 200px;
        cursor: pointer;
        color: $main-theme-yellow;
        border: 1px solid $main-theme-yellow;
        padding: 6px 18px;
        border-radius: 4px;
    }

    .actionButton {
        padding: 6px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        font-weight: 500;
        float: left;
        margin: 0 4px;
        cursor: pointer;
        transition: all .3s;
        user-select: none;

        .actionButtonIcon {
            margin-right: 4px;
        }
    }

    .deactivateButton {
        color: rgb(255, 78, 78);
        border-color: rgb(255, 78, 78);

        &:hover {
            color: white;
            background: rgb(255, 78, 78);
        }
    }

    .verifyButton {
        color: rgb(78, 200, 78);
        border-color: rgb(78, 200, 78);

        &:hover {
            color: white;
            background: rgb(78, 200, 78);
        }
    }

    .themeEditButton {
        color: $color-info;
        border-color: $color-info;

        &:hover {
            color: white;
            background: $color-info;
        }
    }
}

.actionsPopupWrapper {
    z-index: 8;

    .actions {
        display: flex;
        flex-direction: column;
        background: white;
        box-shadow: 2px 2px 10px #eee;

        a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.87);
        }
    
        .actionButton {
            padding: 10px 32px;
            text-align: center;
            cursor: pointer;
    
            &:hover {
                background: #eee;
            }
        }
    }
}