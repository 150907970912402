@import "../../../utils/style/colors.scss";

.guide {
    justify-content: center;

    .guideItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 64px;
        margin: 0 32px;
        width: 625px;
        height: 445px;
        opacity: 0;

        &.flipCard {
            perspective: 1000px;
            padding: 16px 0;

            .flipCardInner {
                position: relative;
                width: 100%;
                height: 100%;
                transition: transform .6s;
                transform-style: preserve-3d;
            }

            &:hover .flipCardInner {
                transform: rotateY(180deg);

                .flipCardFront,
                .flipCardBack {
                    box-shadow: 2px 2px 10px #ddd;
                }
            }

            .flipCardFront,
            .flipCardBack {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                padding: 0 16px;
                backface-visibility: hidden;
            }

            .flipCardFront {
                position: absolute;
                background: white;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .flipCardBack {
                transform: rotateY(180deg);
                background: white;
                color: $main-theme-yellow;
                padding: 16px;
                line-height: 30px;
                letter-spacing: 1px;
                font-size: 18px;
                overflow: auto;
            }
        }

        .subtitle {
            margin-top: 0;
            font-weight: lighter;
        }

        .stepsWrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .stepsIcon {
                font-size: 128px;
                margin-right: 48px;
            }

            .steps {
                display: flex;
                flex-direction: column;

                .step {
                    display: flex;
                    align-items: center;
                    margin: 12px 0;

                    .stepNumber {
                        width: 32px;
                        height: 32px;
                        background: #eaa524aa;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-right: 12px;
                        color: white;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .guide {
        .guideItem {
            padding: 16px 16px;
        }

        .stepsIcon {
            display: none;
        }
    }
}

@media (max-width: 500px) {
    .guide .guideItem.flipCard .flipCardBack {
        font-size: 16px;
    }
}