@import '../../../../../../utils/style/colors.scss';

.row {
    cursor: pointer;

    &:hover {
        background: rgba(200, 200, 200, .1);
    }

    td {
        text-align: center;
        padding: 24px 0;
        display: table-cell;
        color: $main-theme-dark-pink;
    }
}