.multiSelect {
    margin-left: 0;

    &.withLabel > span {
        margin-right: 8px;
        font-weight: 500;
    }

    & > div.MuiInput-root {
        flex-grow: 1;
    }
}