@import '../../../../../../utils/style/colors.scss';

.tableWrapper {
    position: relative;
    width: calc(100% - 32px);
    margin: 32px 16px 16px;
    padding: 12px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, .16);
    background: white;
    z-index: 0;

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            tr {
                border-bottom: 1px solid #eee;
                text-transform: capitalize;

                th {
                    padding-bottom: 8px;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .tableWrapper {
        overflow: auto;

        table {
            width: 890px;
        }
    }
}