.adminPostsWrapper {
    width: 100%;

    .header {
        margin-bottom: 20px;
    }

    .postsGrid {
        justify-content: initial;
        width: 100%;
    }
}

@media (max-width: 1240px) {
    .header {
        .searchBar {
            width: 100%;
            margin-bottom: 32px;
        }
    }
}