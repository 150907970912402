@import "../../../../utils/style/colors.scss";

.link {
    text-decoration: none;

    .postGridItemContainer {
        width: 384px;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: .7rem;
        cursor: pointer;
    
        .verifiedBadge {
            display: flex;
            align-items: center;
            position: absolute;
            left: 0;
            top: 0;
            color: white;
            background-color: #5FD79C;
            border-radius: 0 0 8px 0;
            text-transform: uppercase;
            font-weight: bold;
            padding: 2px 8px;
            z-index: 4;
    
            img {
                margin-right: 4px;
            }
        }
    
        .imagePost {
            height: 196px;
            position: relative;
            overflow: hidden;
    
            & > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
    
            .soldOverlay {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                overflow: visible;
                width: 64px;
                height: 64px;
    
                span {
                    color: snow;
                    font-weight: bold;
                    font-size: 16px;
                    font-style: oblique;
                    margin: 8px;
                    padding: 2px;
                    background-color: red;
                    text-align: center;
                    transform: rotate(45deg);
                    width: 512px;
                }
            }
        }
    
        .location {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: bold;
            margin: 2px 8px;
            color: #A0A0A0;
    
            img {
                margin-right: 4px;
            }
    
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    
        .title {
            margin: 2px 8px;
            font-weight: 425;
            font-size: 16px;
        }
    
        .bidDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 2px 8px;
    
            .price {
                font-weight: bold;
                color: $main-theme-yellow;
                font-size: 16px;
            }
    
            .timeLeft {
                display: flex;
                align-items: center;
                padding-right: 4px;
    
                & > img {
                    margin-right: 4px;
                }
            }
        }
    }
}