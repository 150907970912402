@import "../../../../../utils/style/colors.scss";

.bidsForPostWrapper {
    display: flex;
    flex-direction: column;

    .search {
        margin-top: 8px;
        width: 256px;
    }

    .bidsWrapper {
        display: flex;
        flex-direction: column;
        padding: 8px 12px;

        .bidsHeader {
            display: flex;
            justify-content: space-around;

            h4 {
                width: 33.3333%;
                text-align: center;
            }
        }

        .bidsItems {
            max-height: 300px;
            overflow: auto;
            display: flex;
            flex-direction: column;

            .bidRow {
                display: flex;
                justify-content: space-around;
                align-items: center;

                h5 {
                    width: 33.3333%;
                    text-align: center;

                    a {
                        text-decoration: none;

                        .userButton {
                            color: $main-theme-yellow;
                        }
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            width: 2px;
        }

        ::-webkit-scrollbar-track {
            background: #ccc;
        }

        ::-webkit-scrollbar-thumb {
            background: $main-theme-yellow;
        }
    }
}