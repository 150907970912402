@import "../../utils/style/colors.scss";

.imagesCarousel {
    display: flex;
    overflow-x: auto;
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;

    .imageItem {
        height: 64px;
        object-fit: cover;
        object-position: center;
        margin: 8px;
        cursor: pointer;
        border-radius: 4px;

        &.selected {
            margin: 4px;
            height: 72px;
            border: 4px solid $main-theme-yellow;
        }
    }
}