.fullscreenDialogPageContainer {
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        padding: 32px;
        width: 100%;
        max-width: 1536px;
    }
}