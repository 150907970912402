@import "../../../utils/style/colors.scss";

.helpItem {
    padding: 36px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px;
    min-width: 370px;
    cursor: pointer;

    .iconWrapper {
        width: 96px;
        height: 96px;
        background: #ddd;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        .helpIcon {
            font-size: 48px;
        }
    }

    .title {
        text-align: center;
        margin-top: 24px;
    }

    .description {
        font-weight: lighter;
        margin-top: 0;
        max-width: 200px;
        text-align: center;
    }

    .navigateIcon {
        border-radius: 50%;
        padding: 9px;
        font-size: 42px;
        background: #eaa52422;
        color: $main-theme-yellow;
        opacity: 0;
        transition: all .3s;
    }

    &:hover .navigateIcon {
        opacity: 1;
    }
}