div.realEstateListFiltersWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > * {
        max-width: 350px;
        flex-basis: 100%;
        margin: 16px;
        justify-content: center;
    }
}