.sectionContactAndPricingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .soldBanner {
        background-color: red;
        color: snow;
        padding: 4px;
        width: 100%;
        font-weight: bold;
        text-align: center;
        font-size: 16px;
        border-radius: 4px;
        margin: 0 16px 16px;
    }

    .callUs {
        margin: 16px 0;
    }

    .phoneChip {
        width: min-content;
    }

    a {
        cursor: pointer;
    }
}