$small-image-dimension: 5rem;
$medium-image-dimension: 7rem;

.partnerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;

    .partnerImage {
        max-height: $medium-image-dimension;
        max-width: $medium-image-dimension;

        &.small {
            max-height: $small-image-dimension;
            max-width: $small-image-dimension;
        }
    }
}