@import "../../../utils/style/colors.scss";

div.searchInput {
    margin: 0 16px;
    
    & > div > div > svg > * {
        transition: 0.3s;
    }
}

div.searchInput :global div.Mui-focused > div > svg {
    color: $main-theme-yellow;
}