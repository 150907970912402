span.languageCode {
	font-size: 12px;
	margin-left: 2px;
}

.optionsContainer {
	min-width: 100px;
	background: white;
	border-radius: 2px;

	.languageOption {
		padding: 6px 12px;
		color: black;
		cursor: pointer;

		&:hover {
			background: rgba(234, 165, 36, 0.1);
		}

		&.activeOption {
			color: #eaa524;
		}
	}
}

.languageIcon {
	width: 20px;
	height: 20px;
	margin-right: 4px;
	border: 2px solid white;
	border-radius: 50px;
	margin-top: 4px;

	&.active {
		margin-top: 0;
	}
}

.popper {
	z-index: 1101;
	box-shadow: 2px 2px 15px rgba(200, 200, 200, 0.4);
}
