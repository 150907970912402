.firstOfferWrapper {
    display: flex;
    align-items: center;
    margin: 16px 0;

    & > .numericInput {
        margin-right: 16px;

        & > div {
            width: 192px;
        }
    }
}

.bidsWrapper {
    margin: 16px 0;
}

.bidsLoadingProgress {
    min-height: 184px;
    display: flex;
    align-items: center;
    justify-content: center;
}