@import "../../../../../utils/style/colors.scss";

.titleInput {
    background: rgba(220, 220, 220, .3);
    padding: 16px 24px;
    border-radius: 4px;
    color: $main-theme-dark-pink;
    border: none;
    width: 99%;
    margin: 4px 4px;
    font-size: 16px;
    min-width: 150px;
    resize: none;
    font-family: 'Roboto', sans-serif;

    &:focus {
        outline: none;
    }

    &.error {
        border-bottom: 2px solid red;
    }
}