@import "utils/style/colors.scss";

span.message {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;

    &.boldFont {
        color: $main-theme-yellow;
        font-weight: 500;
    }

    &.lowerCase {
        text-transform: lowercase;
    }
}