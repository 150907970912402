 .fade-in-enter {
     opacity: 0;
     margin-top: -30px !important;
 }

 .fade-in-enter-done {
     opacity: 1 !important;
     margin-top: 0;
     transition: all .3s ease-in;
 }

 .slide-enter {
     left: -100%;
     opacity: 0;
 }

 .slide-enter-done {
     left: 0;
     opacity: 1;
     transition: all .5s;
 }