.settingsType {
    padding: 32px 0;

    .cardContentLanguage {
        padding: 16px;
    }

    .notificationsContainer {
        display: flex;
    }

    .expandHeading {
        flex-basis: 33%;
        font-weight: 500;
    }

    .panelContentWith2 {
        flex-wrap: wrap;
        
        .inputField {
            width: 256px;
            margin: 8px 5% 4px 0;
        }
    }

    .panelContentWith3 {
        flex-wrap: wrap;
        justify-content: space-around;

        .inputField {
            width: 256px;
        }
    }

    .inputField {
        width: 256px;
    }
}