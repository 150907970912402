div.vehiclesCharacteristicsWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .characteristicWrapper {
        height: 64px;
        min-width: 256px;
        max-width: 720px;
        width: 100%;
        padding: 16px 32px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid rgb(230, 230, 230);
    }
}