.generalInformation {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    margin: 0 16px 16px;
    overflow: auto;
    border-radius: 4px;
    box-shadow: 2px 2px 20px rgba(161, 161, 161, .36);
    padding: 25px 50px 50px;

    .userDetails {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;
        padding: 0 20px;

        h4 {
            margin: 12px 0;
        }

        &:hover {
            background: #efefef;
        }

        .userDetailsValue {
            font-weight: lighter;
            font-family: 'Roboto';

            &.clickable {
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 600px) {
    .generalInformation {
        padding: 8px 12px 12px;

        .userDetails {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
}