.benefitItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 275px;
    max-width: 325px;
    margin-bottom: 16px;
    min-height: 350px;
    margin: 0 15px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        margin-top: 16px;
        border-radius: 50%;
    }

    .benefit {
        margin-top: 16px 0;
    }

    .benefitDescription {
        margin-top: 0;
        text-align: center;
        line-height: 30px;
    }
}