label.daysInput {
    margin: 0 4px 0 -2px;
    width: 64px;
}

div.dateInput {
    margin: -4px 4px 0;
    width: 96px;
}

.lowerCase {
    text-transform: lowercase;
}

.dialogContent > p {
    display: inline-block;
}