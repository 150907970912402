.mapWrapper {
    width: 100%;
    position: relative;
    height: 384px;
    
    .locationPicker {
        height: 100%;

        & > div:nth-child(2) {
            display: none;
        }
    }

    .viewOverlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: none;

        & > span {
            display: flex;

            .label {
                margin-right: 8px;
            }
        }

        & > button {
            margin-top: 64px;
        }

        &.show {
            display: flex;
        }
    }

    .editOverlay {
        position: absolute;
        bottom: 32px;
        left: calc(50% - 48px);
        right: calc(50% - 48px);
        display: none;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 32px;

        &.show {
            display: flex;
        }
    }
}