@import "../../../../utils/style/colors.scss";

.formWrapper {
    margin: 24px 5%;
    
    .section {
        border-radius: 4px;
        box-shadow: 2px 2px 15px #ddd;
        padding: 6px 8px;
        margin-top: 12px;

        &::before {
            height: 0px;
        }

        .sectionHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .stepWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 75px;
                height: 35px;
                border-radius: 4px;
                background: #eee;
                transition: all .3s;

                .sectionLabel {
                    margin-top: 0 !important; 
                    letter-spacing: 2px;
                    color: #555;
                }

                &.valid {
                    background: rgba(25, 214, 0, 0.5);

                    .sectionLabel {
                        color: white;
                    }
                }
            }
        }

        .sectionContent {
            padding: 0 24px;
            width: 100%;

            .subsection {
                .subsectionLabel {
                    color: $main-theme-yellow;
                    margin: 12px 0;
                }

                .subsectionContent {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }
    
    .formButtonWrapper {
        display: flex;
        justify-content: flex-end;
        margin: 32px 0 32px 32px;
    }
    
}