.popupContainer {
    z-index: 1399;
    position: fixed;
    top: 56px;
    right: 16px;
    max-width: 30rem;
    width: calc(100vw - 2rem);
    display: flex;
    flex-direction: column-reverse;

    & > div {
        margin-bottom: .5rem;
    }
}