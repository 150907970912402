@import "../../../utils/style/colors.scss";

.centeringContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .mainCard {
        width: 384px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 32px;

        .textSiteName {
            font-weight: lighter;
            color: $main-theme-yellow;
        }

        .emailField {
            width: 320px;
            margin-top: 24px;
        }

        .passwordField {
            width: 320px;
            margin-top: 24px;
        }

        .wrapperForgotPassword {
            width: 320px;
            margin-top: 16px;
            margin-bottom: 32px;
        }

        .groupSignupLogin {
            width: 320px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }
    }
}