@import "../../../../utils/style/colors.scss";

.dropdown {
    user-select: none;
    flex-grow: 4;
    margin: 0 16px;
    color: $main-theme-dark-pink;
    cursor: pointer;

    h4 {
        text-transform: uppercase;
    }
}

.popper {
    z-index: 1000;

    .dropdownItems {
        padding: 12px 0;
        box-shadow: 3px 3px 20px rgba(0, 0, 0, .16);
        background: white;
        margin: 10px 0 0;
        z-index: 1000;

        div {
            padding: 12px 75px;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 2px;
            cursor: pointer;

            &.active {
                color: $main-theme-yellow;
            }
        }
    }
}

@media (max-width: 1060px) {
    .dropdown {
        width: calc(50% - 64px);
        margin-bottom: 16px;
    }
}

@media (max-width: 500px) {
    .dropdown {
        width: 100%;
    }
}