.footer {
    width: 100%;
    border-top: 1px solid #eee;
    padding: 32px 48px;
    display: flex;
    flex-direction: column;
    position: relative;

    .nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #999;
        width: 100%;

        .appName > span {
            color: black;
            font-weight: 700;
        }

        .navItems {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            left: 160px;
            right: 160px;
            max-width: 1024px;
            margin: 0 auto;
            position: absolute;

            span {
                border-bottom: 1px solid transparent;
                transition: border-color .2s;
                cursor: pointer;

                &:hover {
                    border-bottom-color: #ccc;
                }
            }
        }
    }

    .copyrightTerms {
        margin-top: 32px;
        display: flex;
        flex-direction: row;

        .copyright {
            flex-grow: 15;
            color: #bbb;
        }

        .terms {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            color: #bbb;

            span {
                border-bottom: 1px solid transparent;
                transition: border-color .2s;
                cursor: pointer;

                &:hover {
                    border-bottom-color: #ccc;
                }
            }
        }
    }
}

@media (max-width: 620px) {
    .footer {
        padding: 32px;

        .nav {
            display: none;
        }

        .copyrightTerms {
            margin-top: 0;
        }
    }
}