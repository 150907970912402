.imagePickerWrapper {
    position: relative;
    width: 100%;

    .imageCounter {
        position: absolute;
        right: 32px;
        top: 0;
        background-color: rgb(228, 228, 228);
        padding: 2px 8px;
        border-radius: 8px;
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;
    }
}