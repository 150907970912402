@import "../../../../../../../../../utils/style/colors.scss";

tr.bidsForPost td {
    padding: 12px 0;
    text-align: center;

    .seePost {
        display: flex;
        justify-content: center;
        background: $main-theme-yellow;
        border-radius: 4px;
        color: white;
        padding: 6px 0;
        cursor: pointer;
    }

    span {
        display: block;
        padding: 8px;
    }
}
