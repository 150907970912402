div.rangeContainer {
    display: flex;
    align-items: center;
    flex-flow: wrap;

    & > p {
        font-weight: bold;
    }

    .inputs {
        display: flex;
        align-items: center;
        white-space: nowrap;

        .rangeInput {
            min-width: 72px;
            margin: 0 16px;
        }
    
        .rangeSlider {
            min-width: 128px;
        }
    }
}