@import '../../../utils/style/colors.scss';

.navigation {
    top: 0;
    left: 0;
    min-height: 100vh;
    background: $main-theme-yellow;

    .navigationHeader {
        display: flex;
        align-items: center;

        .tooltip {
            height: 48px;
            margin-left: 12px;
            color: white;
        }

        h1 {
            padding: 24px 24px 24px 12px;
            font-size: 32px;
            margin: 0;
            color: white;
            font-weight: lighter;
        }

        .mobileNavigationClose {
            display: none;
        }
    }

    .navigationListWrapper {
        padding: 0;
        margin: 0;
        width: 100%;
        margin-top: 80px;

        a {
            position: relative;
            font-size: 16px;
            min-width: 330px;
            height: 100%;
            display: block;
            text-decoration: none;
            color: white;
            padding: 0 80px;
            line-height: 80px;
            transition: all .5s ease-in-out;
            font-weight: bold;
            cursor: pointer;

            .icon {
                margin-top: -5px;
                margin-right: 16px;
                font-size: 24px;
                vertical-align: middle;
            }

            &.active {
                background: white;
                color: $main-theme-yellow;
                font-size: 24px;

                .icon {
                    font-size: 32px;
                }
            }
        }
    }
}

button.mobileNavigation {
    background: $main-theme-yellow;
    color: white;
    border-radius: 50%;
    position: fixed;
    top: 15px;
    right: 15px;
    display: none;

    &.back {
        left: 15px;
    }
}

@media(max-width: 1440px) {
    .navigation {
        .navigationHeader {

            h1 {
                font-size: 26px;
                padding: 16px 16px 16px 6px;
            }
        }

        .navigationListWrapper {
            a {
                min-width: 250px;
                font-size: 14px;
                padding: 0 40px;

                &.active {
                    font-size: 18px;

                    .icon {
                        font-size: 26px;
                    }
                }

                .icon {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .navigation {
        .navigationHeader {
            h1 {
                font-size: 18px;
                padding: 8px 8px 8px 6px;
            }
        }

        .navigationListWrapper {
            a {
                min-width: 200px;
                font-size: 13px;
                padding: 0 30px;

                &.active {
                    font-size: 16px;

                    .icon {
                        font-size: 24px;
                    }
                }

                .icon {
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .navigation {
        position: absolute;
        width: 100%;
        left: -100%;
        top: 0;
        transition: all .2s;
        z-index: 10000;

        .navigationHeader {
            display: flex;
            align-items: center;
            padding: 20px 30px 0;

            h1 {
                flex-grow: 4;
                font-size: 26px;
                padding: 16px 16px 16px 6px;
                text-align: center;
            }

            .mobileNavigationClose {
                display: block;
            }
        }

        .navigationListWrapper {
            a {
                min-width: 250px;
                font-size: 20px;
                padding: 0 40px;
                text-align: center;
                margin-bottom: 20px;

                &.active {
                    font-size: 24px;

                    .icon {
                        font-size: 26px;
                    }
                }

                .icon {
                    font-size: 20px;
                }
            }
        }

        &.active {
            left: 0;
        }
    }

    button.mobileNavigation {
        display: block;
    }
}