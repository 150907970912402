.popper {
    z-index: 1400;

    .pane {
        border-radius: 1rem;
        max-width: 30rem;
        width: calc(100vw - 2rem);
        margin-top: .5rem;
    
        .header {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
    
            .closeButton {
                border: 0.1em solid rgb(224, 224, 224);
                border-radius: 0.5em;
    
                svg {
                    fill: silver;
                }
            }
        }
    
        .notificationsContainer {
            max-height: 75vh;
            overflow-y: auto;

            .noNotifications {
                font-style: italic;
                margin: 1rem;
                text-align: center;
            }
        }
    }
}