.priceSummary {
    display: flex;

    & > label {
        color: gray;
        margin-left: 64px;
    }
}

.priceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all;

    & > label {
        margin: 0;
    }
}